<template>
  <div></div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import useGoogle from './useGoogle';

export default defineComponent({
  setup() {
    const route = useRoute();
    // const auth = inject('auth');
    const google = useGoogle();
    google.sign({ ...route.query, isRedirect: 1 });

    return { loading: google.loading };
  },
});
</script>
